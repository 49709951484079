import { default as ImageModalComponentsQisC6a17IfMeta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/camera/components/ImageModalComponents.vue?macro=true";
import { default as ImageModalInfoLc6ewNQtxrMeta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/camera/components/ImageModalInfo.vue?macro=true";
import { default as indexHq4WrNYFVwMeta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/camera/index.vue?macro=true";
import { default as indexcKFYuOUnVIMeta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/dropout/index.vue?macro=true";
import { default as GlobalGalleryComponentDibBWS1PRYMeta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/gallery/components/GlobalGalleryComponent.vue?macro=true";
import { default as UserGalleryComponentsA689a1W5VMeta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/gallery/components/UserGalleryComponent.vue?macro=true";
import { default as indexeLCv6T5esqMeta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/gallery/index.vue?macro=true";
import { default as ImageComponentUM1bMCWPPcMeta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/images/components/ImageComponent.vue?macro=true";
import { default as ImageDetailsModalme3phkgYjrMeta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/images/components/ImageDetailsModal.vue?macro=true";
import { default as NearbyRecyclingCenterInfoA1Om3mH6ZBMeta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/images/components/NearbyRecyclingCenterInfo.vue?macro=true";
import { default as RecyclingCenterCardoFsO5t5S4LMeta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/images/components/RecyclingCenterCard.vue?macro=true";
import { default as indexEHYAYz1PnYMeta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/images/index.vue?macro=true";
import { default as indexIaOPiC2rsTMeta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/index.vue?macro=true";
import { default as indexgs90aoboIXMeta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/intro/index.vue?macro=true";
import { default as Step1ComponentiHVP1hOW3LMeta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/intro/stepper/Step1Component.vue?macro=true";
import { default as Step2ComponentIGYdYMxO1CMeta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/intro/stepper/Step2Component.vue?macro=true";
import { default as Step3ComponentfnTGoWWTpoMeta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/intro/stepper/Step3Component.vue?macro=true";
import { default as loginCEZsulophvMeta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/login.vue?macro=true";
import { default as logoutEknpYVMi1vMeta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/logout.vue?macro=true";
import { default as ListPostsyBkAy53ulvMeta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/posts/components/ListPosts.vue?macro=true";
import { default as Postw10p0m4CUFMeta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/posts/components/Post.vue?macro=true";
import { default as indexIjfOr0A2urMeta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/posts/index.vue?macro=true";
import { default as index1zBBf9WmLzMeta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/profile/index.vue?macro=true";
import { default as indexMLRdWtncdSMeta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/settings/index.vue?macro=true";
import { default as indexXKJtpkPC76Meta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/support/index.vue?macro=true";
import { default as index6i6U0TRBcyMeta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/tips/index.vue?macro=true";
import { default as DisposalPlaceComponent6cOu0sAYcfMeta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/your_disposal_places/components/DisposalPlaceComponent.vue?macro=true";
import { default as TrashCanDetailsB1hrALADmXMeta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/your_disposal_places/components/TrashCanDetails.vue?macro=true";
import { default as indexTZ4j3BUp6nMeta } from "D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/your_disposal_places/index.vue?macro=true";
export default [
  {
    name: "camera-components-ImageModalComponents___en",
    path: "/camera/components/ImageModalComponents",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/camera/components/ImageModalComponents.vue").then(m => m.default || m)
  },
  {
    name: "camera-components-ImageModalComponents___es",
    path: "/es/camera/components/ImageModalComponents",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/camera/components/ImageModalComponents.vue").then(m => m.default || m)
  },
  {
    name: "camera-components-ImageModalComponents___pt",
    path: "/pt/camera/components/ImageModalComponents",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/camera/components/ImageModalComponents.vue").then(m => m.default || m)
  },
  {
    name: "camera-components-ImageModalInfo___en",
    path: "/camera/components/ImageModalInfo",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/camera/components/ImageModalInfo.vue").then(m => m.default || m)
  },
  {
    name: "camera-components-ImageModalInfo___es",
    path: "/es/camera/components/ImageModalInfo",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/camera/components/ImageModalInfo.vue").then(m => m.default || m)
  },
  {
    name: "camera-components-ImageModalInfo___pt",
    path: "/pt/camera/components/ImageModalInfo",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/camera/components/ImageModalInfo.vue").then(m => m.default || m)
  },
  {
    name: "camera___en",
    path: "/camera",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/camera/index.vue").then(m => m.default || m)
  },
  {
    name: "camera___es",
    path: "/es/camara",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/camera/index.vue").then(m => m.default || m)
  },
  {
    name: "camera___pt",
    path: "/pt/camera",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/camera/index.vue").then(m => m.default || m)
  },
  {
    name: "dropout___en",
    path: "/dropout",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/dropout/index.vue").then(m => m.default || m)
  },
  {
    name: "dropout___es",
    path: "/es/dropout",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/dropout/index.vue").then(m => m.default || m)
  },
  {
    name: "dropout___pt",
    path: "/pt/dropout",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/dropout/index.vue").then(m => m.default || m)
  },
  {
    name: "gallery-components-GlobalGalleryComponent___en",
    path: "/gallery/components/GlobalGalleryComponent",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/gallery/components/GlobalGalleryComponent.vue").then(m => m.default || m)
  },
  {
    name: "gallery-components-GlobalGalleryComponent___es",
    path: "/es/gallery/components/GlobalGalleryComponent",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/gallery/components/GlobalGalleryComponent.vue").then(m => m.default || m)
  },
  {
    name: "gallery-components-GlobalGalleryComponent___pt",
    path: "/pt/gallery/components/GlobalGalleryComponent",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/gallery/components/GlobalGalleryComponent.vue").then(m => m.default || m)
  },
  {
    name: "gallery-components-UserGalleryComponent___en",
    path: "/gallery/components/UserGalleryComponent",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/gallery/components/UserGalleryComponent.vue").then(m => m.default || m)
  },
  {
    name: "gallery-components-UserGalleryComponent___es",
    path: "/es/gallery/components/UserGalleryComponent",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/gallery/components/UserGalleryComponent.vue").then(m => m.default || m)
  },
  {
    name: "gallery-components-UserGalleryComponent___pt",
    path: "/pt/gallery/components/UserGalleryComponent",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/gallery/components/UserGalleryComponent.vue").then(m => m.default || m)
  },
  {
    name: "gallery___en",
    path: "/gallery",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/gallery/index.vue").then(m => m.default || m)
  },
  {
    name: "gallery___es",
    path: "/es/galeria",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/gallery/index.vue").then(m => m.default || m)
  },
  {
    name: "gallery___pt",
    path: "/pt/galeria",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/gallery/index.vue").then(m => m.default || m)
  },
  {
    name: "images-components-ImageComponent___en",
    path: "/images/components/ImageComponent",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/images/components/ImageComponent.vue").then(m => m.default || m)
  },
  {
    name: "images-components-ImageComponent___es",
    path: "/es/images/components/ImageComponent",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/images/components/ImageComponent.vue").then(m => m.default || m)
  },
  {
    name: "images-components-ImageComponent___pt",
    path: "/pt/images/components/ImageComponent",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/images/components/ImageComponent.vue").then(m => m.default || m)
  },
  {
    name: "images-components-ImageDetailsModal___en",
    path: "/images/components/ImageDetailsModal",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/images/components/ImageDetailsModal.vue").then(m => m.default || m)
  },
  {
    name: "images-components-ImageDetailsModal___es",
    path: "/es/images/components/ImageDetailsModal",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/images/components/ImageDetailsModal.vue").then(m => m.default || m)
  },
  {
    name: "images-components-ImageDetailsModal___pt",
    path: "/pt/images/components/ImageDetailsModal",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/images/components/ImageDetailsModal.vue").then(m => m.default || m)
  },
  {
    name: "images-components-NearbyRecyclingCenterInfo___en",
    path: "/images/components/NearbyRecyclingCenterInfo",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/images/components/NearbyRecyclingCenterInfo.vue").then(m => m.default || m)
  },
  {
    name: "images-components-NearbyRecyclingCenterInfo___es",
    path: "/es/images/components/NearbyRecyclingCenterInfo",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/images/components/NearbyRecyclingCenterInfo.vue").then(m => m.default || m)
  },
  {
    name: "images-components-NearbyRecyclingCenterInfo___pt",
    path: "/pt/images/components/NearbyRecyclingCenterInfo",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/images/components/NearbyRecyclingCenterInfo.vue").then(m => m.default || m)
  },
  {
    name: "images-components-RecyclingCenterCard___en",
    path: "/images/components/RecyclingCenterCard",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/images/components/RecyclingCenterCard.vue").then(m => m.default || m)
  },
  {
    name: "images-components-RecyclingCenterCard___es",
    path: "/es/images/components/RecyclingCenterCard",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/images/components/RecyclingCenterCard.vue").then(m => m.default || m)
  },
  {
    name: "images-components-RecyclingCenterCard___pt",
    path: "/pt/images/components/RecyclingCenterCard",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/images/components/RecyclingCenterCard.vue").then(m => m.default || m)
  },
  {
    name: "images___en",
    path: "/images/:id",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/images/index.vue").then(m => m.default || m)
  },
  {
    name: "images___es",
    path: "/es/imagenes/:id",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/images/index.vue").then(m => m.default || m)
  },
  {
    name: "images___pt",
    path: "/pt/imagens/:id",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/images/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    meta: indexIaOPiC2rsTMeta || {},
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    meta: indexIaOPiC2rsTMeta || {},
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___pt",
    path: "/pt",
    meta: indexIaOPiC2rsTMeta || {},
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "intro___en",
    path: "/intro",
    meta: indexgs90aoboIXMeta || {},
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/intro/index.vue").then(m => m.default || m)
  },
  {
    name: "intro___es",
    path: "/es/introduccion",
    meta: indexgs90aoboIXMeta || {},
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/intro/index.vue").then(m => m.default || m)
  },
  {
    name: "intro___pt",
    path: "/pt/introducao",
    meta: indexgs90aoboIXMeta || {},
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/intro/index.vue").then(m => m.default || m)
  },
  {
    name: "intro-stepper-Step1Component___en",
    path: "/intro/stepper/Step1Component",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/intro/stepper/Step1Component.vue").then(m => m.default || m)
  },
  {
    name: "intro-stepper-Step1Component___es",
    path: "/es/intro/stepper/Step1Component",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/intro/stepper/Step1Component.vue").then(m => m.default || m)
  },
  {
    name: "intro-stepper-Step1Component___pt",
    path: "/pt/intro/stepper/Step1Component",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/intro/stepper/Step1Component.vue").then(m => m.default || m)
  },
  {
    name: "intro-stepper-Step2Component___en",
    path: "/intro/stepper/Step2Component",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/intro/stepper/Step2Component.vue").then(m => m.default || m)
  },
  {
    name: "intro-stepper-Step2Component___es",
    path: "/es/intro/stepper/Step2Component",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/intro/stepper/Step2Component.vue").then(m => m.default || m)
  },
  {
    name: "intro-stepper-Step2Component___pt",
    path: "/pt/intro/stepper/Step2Component",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/intro/stepper/Step2Component.vue").then(m => m.default || m)
  },
  {
    name: "intro-stepper-Step3Component___en",
    path: "/intro/stepper/Step3Component",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/intro/stepper/Step3Component.vue").then(m => m.default || m)
  },
  {
    name: "intro-stepper-Step3Component___es",
    path: "/es/intro/stepper/Step3Component",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/intro/stepper/Step3Component.vue").then(m => m.default || m)
  },
  {
    name: "intro-stepper-Step3Component___pt",
    path: "/pt/intro/stepper/Step3Component",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/intro/stepper/Step3Component.vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "/login",
    meta: loginCEZsulophvMeta || {},
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___es",
    path: "/es/login",
    meta: loginCEZsulophvMeta || {},
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___pt",
    path: "/pt/login",
    meta: loginCEZsulophvMeta || {},
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "logout___en",
    path: "/logout",
    meta: logoutEknpYVMi1vMeta || {},
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: "logout___es",
    path: "/es/logout",
    meta: logoutEknpYVMi1vMeta || {},
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: "logout___pt",
    path: "/pt/logout",
    meta: logoutEknpYVMi1vMeta || {},
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: "posts-components-ListPosts___en",
    path: "/posts/components/ListPosts",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/posts/components/ListPosts.vue").then(m => m.default || m)
  },
  {
    name: "posts-components-ListPosts___es",
    path: "/es/posts/components/ListPosts",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/posts/components/ListPosts.vue").then(m => m.default || m)
  },
  {
    name: "posts-components-ListPosts___pt",
    path: "/pt/posts/components/ListPosts",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/posts/components/ListPosts.vue").then(m => m.default || m)
  },
  {
    name: "posts-components-Post___en",
    path: "/posts/components/Post",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/posts/components/Post.vue").then(m => m.default || m)
  },
  {
    name: "posts-components-Post___es",
    path: "/es/posts/components/Post",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/posts/components/Post.vue").then(m => m.default || m)
  },
  {
    name: "posts-components-Post___pt",
    path: "/pt/posts/components/Post",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/posts/components/Post.vue").then(m => m.default || m)
  },
  {
    name: "posts___en",
    path: "/learn_more",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/posts/index.vue").then(m => m.default || m)
  },
  {
    name: "posts___es",
    path: "/es/aprende_mas",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/posts/index.vue").then(m => m.default || m)
  },
  {
    name: "posts___pt",
    path: "/pt/saiba_mais",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/posts/index.vue").then(m => m.default || m)
  },
  {
    name: "profile___en",
    path: "/profile",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile___es",
    path: "/es/perfil",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile___pt",
    path: "/pt/perfil",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "settings___en",
    path: "/settings",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings___es",
    path: "/es/configuracion",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings___pt",
    path: "/pt/configuracoes",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "support___en",
    path: "/support",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: "support___es",
    path: "/es/suporte",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: "support___pt",
    path: "/pt/suporte",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: "tips___en",
    path: "/tips",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/tips/index.vue").then(m => m.default || m)
  },
  {
    name: "tips___es",
    path: "/es/consejos",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/tips/index.vue").then(m => m.default || m)
  },
  {
    name: "tips___pt",
    path: "/pt/dicas",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/tips/index.vue").then(m => m.default || m)
  },
  {
    name: "your_disposal_places-components-DisposalPlaceComponent___en",
    path: "/your_disposal_places/components/DisposalPlaceComponent",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/your_disposal_places/components/DisposalPlaceComponent.vue").then(m => m.default || m)
  },
  {
    name: "your_disposal_places-components-DisposalPlaceComponent___es",
    path: "/es/your_disposal_places/components/DisposalPlaceComponent",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/your_disposal_places/components/DisposalPlaceComponent.vue").then(m => m.default || m)
  },
  {
    name: "your_disposal_places-components-DisposalPlaceComponent___pt",
    path: "/pt/your_disposal_places/components/DisposalPlaceComponent",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/your_disposal_places/components/DisposalPlaceComponent.vue").then(m => m.default || m)
  },
  {
    name: "your_disposal_places-components-TrashCanDetails___en",
    path: "/your_disposal_places/components/TrashCanDetails",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/your_disposal_places/components/TrashCanDetails.vue").then(m => m.default || m)
  },
  {
    name: "your_disposal_places-components-TrashCanDetails___es",
    path: "/es/your_disposal_places/components/TrashCanDetails",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/your_disposal_places/components/TrashCanDetails.vue").then(m => m.default || m)
  },
  {
    name: "your_disposal_places-components-TrashCanDetails___pt",
    path: "/pt/your_disposal_places/components/TrashCanDetails",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/your_disposal_places/components/TrashCanDetails.vue").then(m => m.default || m)
  },
  {
    name: "your_disposal_places___en",
    path: "/your_disposal_places",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/your_disposal_places/index.vue").then(m => m.default || m)
  },
  {
    name: "your_disposal_places___es",
    path: "/es/sus_lugares_de_disposicion",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/your_disposal_places/index.vue").then(m => m.default || m)
  },
  {
    name: "your_disposal_places___pt",
    path: "/pt/seus_locais_de_descarte",
    component: () => import("D:/PROGRAMAS/trash-main/trash-main/guis/web/pages/your_disposal_places/index.vue").then(m => m.default || m)
  }
]